import React from 'react'

import Page from '../../components/page'
import withRoot from '../../withRoot'

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  table: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6)
  },
  premium: {
    color: theme.palette.primary.light,
    fontWeight: 'bold'
  },
  button: {
    marginTop: theme.spacing(3),
    width: '100%'
  },
})

@withStyles(styles)
class PremiumButton extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <a href="https://fitface.xyz/user/account">
        <Button align="center" variant="contained" color="secondary" className={classes.button}>
          Become Premium
        </Button>
      </a>
    )
  }
}

@withRoot
@withStyles(styles)
export default class Premium extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <Page toggleTheme={this.props.toggleTheme} title="Premium">
        <Typography>
          FitFace gives you access to several awesome features for free.<br />
          But you can take your faces to the next level by unlocking the premium ones for a small price!
        </Typography>
        <PremiumButton />
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="center">REGULAR</TableCell>
              <TableCell className={classes.premium} align="center">PREMIUM</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">Free</TableCell>
              <TableCell className={classes.premium} align="center">Only $40 / year (+ local taxes)</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} align="center">Create and Publish Faces</TableCell>
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell className={classes.premium} align="center">Share private Faces with other Fitface user</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} align="center">Access to your Health Data</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} align="center">Dynamic Tags & Expressions</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} align="center">Theme Customization</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} align="center">Sell Faces & Get Paid</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">5 Favorites</TableCell>
              <TableCell className={classes.premium} align="center">Unlimited Favorites</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">72h Support via Discord or Email</TableCell>
              <TableCell className={classes.premium} align="center">24h Support via Discord or Email</TableCell>
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell className={classes.premium} align="center">Reduced transaction fees</TableCell>
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell className={classes.premium} align="center">Custom Fonts</TableCell>
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell className={classes.premium} align="center">Curved Text (Versa 3 only)</TableCell>
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell className={classes.premium} align="center">Weather Data</TableCell>
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell className={classes.premium} align="center">Animated PNG</TableCell>
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell className={classes.premium} align="center">Masks</TableCell>
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell className={classes.premium} align="center">Api</TableCell>
            </TableRow>
            {/*<TableRow>
              <TableCell />
              <TableCell className={classes.premium} align="center">No ads</TableCell>
            </TableRow>*/}
            <TableRow>
              <TableCell />
              <TableCell className={classes.premium} align="center">Automatic access to the upcoming features.</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Typography>
          You can test all the premium features in the editor, you just will not be able to save your design if you’re not premium.
        </Typography>
        <PremiumButton />
      </Page>
    )
  }
}
